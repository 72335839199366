import React from 'react'
import Hero from '../components/Hero'
import QuoteBox from '../components/QuoteBox'
import CallToAction from '../components/CallToAction'
import Video from '../components/Video'
import NumberedCard from '../components/NumberedCard'
import iconSupport from '../images/icon-support.svg'

const SupportServicesPage = () => {
  return (
    <main className="supportServicesPage">
      <Hero
        heading="Help is right at your fingertips"
        text="Aidia focuses on your medication schedule, so you can focus on what matters most."
        image="clock-devices"
      />

      <div className="page-section here-for-you">
        <div className="page-section-content">
          <img src={iconSupport} alt="support" className="support-icon" />
          <div className="page-section-text">
            <h2>We&apos;re here for you</h2>
            <p>
              If you are currently enrolled in a smart bottle or cap program, you can contact us or your pharmacy with
              any questions related to the Aidia System&trade;
            </p>
          </div>
        </div>
        <div className="page-section-content speak-with-specialist">
          <div className="speak-with-specialist-col">
            <h3>Speak with an Aidia Specialist</h3>
            <p className="contact-info">
              <strong>(800) 381-9384</strong>, Monday-Friday (8am - 8pm ET), Saturday-Sunday (11am - 5pm ET)*
            </p>
            <p className="footnote">*Business hours are subject to change during holidays.</p>
            <div className="note">
              <strong>Please note:</strong> If you are in need of medical attention or this is a medical emergency,
              please contact your healthcare provider or pharmacy or call 911 for immediate medical attention.
            </div>
          </div>
          <div className="speak-with-specialist-col">
            <h3>Hable con un especialista de Aidia</h3>
            <p className="contact-info">
              <strong>(800) 381-9384</strong>, de lunes a viernes (de 8 am a 8 pm horario del este), de sábado a domingo
              (de 11 am a 5 pm hora del este)*
            </p>
            <p className="footnote">*El horario comercial está sujeto a cambios durante los días festivos.</p>
            <div className="note">
              <strong>Aviso:</strong> Si necesita atención médica de emergencia, por favor contacte a su médico
              principal, farmacia o llame al 911 para atención médica inmediata.
            </div>
          </div>
        </div>
      </div>
      <div className="page-section">
        <QuoteBox
          text="It’s nice to know that when I need help, I’m actually talking to somebody. Aidia Specialists are like my ‘genie’ in the bottle."
          speaker="Aidia user from Arizona"
        />
      </div>

      <div className="page-section simple-right">
        <h2>Simple, right out of the box</h2>
        <Video
          videoSrcURL="https://www.youtube.com/embed/v-1nzNUIN6M"
          videoTitle="Aidia Smart Pill Bottle Instructions For Use"
        />
      </div>

      <div className="page-section aidia-steps">
        <NumberedCard
          number={1}
          header={`Activate`}
          text={`Remove the orange pull-tab from the Aidia Smart Bottle (<em>if the pharmacy hasn’t already</em>). Your
              Aidia Smart Bottle will light up and chime to let you know it’s ready.`}
        />
        <NumberedCard
          number={2}
          header={`Check`}
          text={`Your pharmacy may have already put your medication in the bottle. If not, simply transfer your medication
              from your regular pharmacy bottle into your Aidia Smart Bottle.`}
        />
        <NumberedCard
          number={3}
          header={`Relax`}
          text={`Aidia has been set to your dosing schedule and works automatically.`}
        />
      </div>

      <div className="page-section">
        <div className="page-section-content call-to-action-grid">
          <CallToAction
            headerText="Want to learn more about your Aidia System?"
            linkText="FAQs"
            linkUrl="/faqs"
            color="green"
          />
          <CallToAction
            headerText="Have questions about your Aidia System?"
            linkText="Contact an Aidia Specialist"
            linkUrl="/patient-support"
            color="green"
          />
        </div>
      </div>
    </main>
  )
}

export default SupportServicesPage
