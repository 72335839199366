import React from 'react'

const NumberedCard = ({ number, header, text, children }) => {
  return (
    <div className="numbered-card">
      <span className="step-number">{number}</span>
      <div className="card-content">
        <h2>{header}</h2>
        {text && <p dangerouslySetInnerHTML={{ __html: text }}></p>}
        {!text && children}
      </div>
    </div>
  )
}

export default NumberedCard
