import React from 'react'

export default function QuoteBox({ color, text, speaker, shadow = true }) {
  let textFill = '#95ea00'
  if (color === 'blue') {
    textFill = '#12a1df'
  }

  return (
    <div className={`quote-box ${shadow ? 'shadow' : ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="67" height="138" viewBox="0 0 67 138" className="quotation-mark">
        <text
          fill={textFill}
          fontFamily="Roboto-Light, Roboto"
          fontSize="180px"
          fontWeight="300"
          letterSpacing="0.005em"
        >
          <tspan x="0" y="150">
            “
          </tspan>
        </text>
      </svg>
      <div className="quote-wrapper">
        <p className="quote-text">{text}</p>
        {speaker && (
          <div className="quote-speaker__container">
            <p className="quote-speaker dash">&ndash;</p>
            <p className="quote-speaker quote">{speaker}</p>
          </div>
        )}
      </div>
    </div>
  )
}
